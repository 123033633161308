import api from 'config/api'
import { IFormattedUser, ISendVipDto, ITransaction, IUserDevice, IUserReferrals } from 'store/fetchers/users/types'

export const getUser = async (): Promise<IFormattedUser> => {
  try {
    const { data } = await api.get('/v2/users')
    return data.response
  } catch (error) {
    throw new Error(error)
  }
}

export const getUserReferrals = async (): Promise<IUserReferrals> => {
  try {
    const { data } = await api.get('/v2/users/referral')
    return data.response
  } catch (err) {
    return null
  }
}

export const requestUserReferral = async (): Promise<unknown> => {
  try {
    const { data } = await api.post('/v2/users/referral-request')
    return data.response
  } catch (err) {
    throw new Error(err)
  }
}

export const sendVip = async (params: ISendVipDto): Promise<unknown> => {
  try {
    const { data } = await api.post('/v2/users/send-vip', { params: params })
    return data.result
  } catch (err) {
    return null
  }
}

export const updateUser = async (params: {
  code_2fa: string
  verification_token: [string, string]
}): Promise<boolean> => {
  try {
    const { data } = await api.put('/v2/users/credentials', {
      params: params,
    })
    return data.result
  } catch (error) {
    return false
  }
}

export const setFallbackEmail = async (params: {
  code_2fa: string
  verification_token: string[]
  new_email: string
}): Promise<boolean> => {
  try {
    const { data } = await api.put('/v2/users/fallback-email', {
      params: params,
    })
    return data.result
  } catch (error) {
    return false
  }
}

export const changeEmail = async (params: {
  verification_token: [string, string]
  new_email: string
  code_2fa: string
}): Promise<boolean> => {
  try {
    const { data } = await api.put('/v2/users/change-email', {
      params: params,
    })
    return data.result
  } catch (error) {
    return false
  }
}

export const changePhone = async (params: {
  code_2fa: string
  verification_token: string
  new_phone: string
}): Promise<boolean> => {
  try {
    const { data } = await api.put('/v2/users/change-phone', {
      params: params,
    })
    return data.result
  } catch (error) {
    return false
  }
}

export const fetchTransactions = async (params: {
  accountType?: string
  accountId?: string
  transactionType?: string
  currencyCode?: string
  currencyNetwork?: string
  search?: string
}): Promise<ITransaction[]> => {
  try {
    const { data } = await api.get('/v2/users/transactions', {
      params,
    })
    return data.response
  } catch (err) {
    return []
  }
}

export const getDevices = async (): Promise<IUserDevice[]> => {
  try {
    const { data } = await api.get('/v2/users/saved-device')
    return data.response
  } catch (err) {
    return []
  }
}
