import { createElement } from 'react'

export const goToChat = () => {
  let button = document.getElementById('zd-button')
  if (!button) {
    const iframe = document.getElementById('launcher') as HTMLIFrameElement

    if (iframe) {
      const iframeDoc = iframe.contentDocument || iframe.contentWindow.document
      button = iframeDoc.querySelector('[data-garden-id="buttons.icon_button"]')
    } else {
      goToTgChat()
    }
  }
  if (button) {
    button.click()
  }
}

export const goToTgChat = () => {
  window.open('https://t.me/crypto_customercare', '_blank')
}

export const switchToTelegram = () => {
  // Создаем наблюдатель за изменениями в DOM
  const observer = new MutationObserver((mutationsList, observer) => {
    const button = document.getElementById('zd-button')

    const iframe = document.getElementById('launcher') as HTMLIFrameElement
    if (iframe) {
      const container = document.createElement('div')
      const iframeWindow = document.querySelector('iframe[title="Messaging window"]')
      if (iframeWindow) {
        iframeWindow.replaceWith(container)
      }
      iframe.replaceWith(container)

      // Динамический импорт компонента TelegramButton
      import('react-dom').then((ReactDOM) => {
        import('../components/app/telegram-button').then((module) => {
          const TelegramButton = module.default
          ReactDOM.render(createElement(TelegramButton), container) // Рендерим компонент
        })
      })
      document.body.appendChild(container)

      // Останавливаем наблюдение после замены кнопки
      observer.disconnect()
    }

    // Если кнопка появилась, заменяем её на компонент TelegramButton
    if (button) {
      const container = document.createElement('div')
      button.replaceWith(container)

      // Динамический импорт компонента TelegramButton
      import('react-dom').then((ReactDOM) => {
        import('../components/app/telegram-button').then((module) => {
          const TelegramButton = module.default
          ReactDOM.render(TelegramButton, container) // Рендерим компонент
        })
      })
      // Останавливаем наблюдение после замены кнопки
      observer.disconnect()
    }
  })

  // Запускаем наблюдатель на всём документе
  observer.observe(document.body, { childList: true, subtree: true })
}
